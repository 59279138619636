import { Button, HStack } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import AppSelectInput from "components/AutocompleteDropDown/AppSelectInput"
import { useEntity } from "hooks/useEntity"
import { useEntityList } from "hooks/useEntityList"
import { RouteParams } from "navigation/RouteParams"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { FiEye, FiSliders } from "react-icons/fi"
import { Link, useHistory, useParams } from "react-router-dom"
import { useUpdateEffect } from "react-use"
import { useQueryClient } from "react-query"
import api from "services/api"

const NODE_ENV = process.env.NODE_ENV
interface Props {}

const AppHeaderActions: React.FC<Props> = () => {
  const history = useHistory()
  const { appId } = useParams<RouteParams>()
  const { data: app } = useEntity<AppEntity>("apps", Number(appId))

  const defaultValues = { id: appId }
  const methods = useForm<AppEntity>({ defaultValues })
  const { watch } = methods

  const {
    data: { data },
    isLoading,
  } = useEntityList<AppEntity>("apps", {
    query: {
      $limit: 1000,
      $sort: {
        name: 1,
      },
    },
  })

  const selectedAppId = watch("id")

  useUpdateEffect(() => {
    if (selectedAppId !== app.id) {
      history.push(`/admin/apps/${selectedAppId}`)
    }
  }, [selectedAppId])

  const queryClient = useQueryClient()

  const handlPrefetchGitReference = async () => {
    await queryClient.prefetchQuery(
      [
        "deployments",
        {
          appId: appId,
          $limit: 1,
          status: NODE_ENV === "development" ? "pending" : "complete",
          type: "live",
          $sort: { createdAt: "desc" },
        },
      ],
      async () => {
        try {
          const data = await api.findOne("deployments", {
            query: {
              appId: appId,
              $limit: 1,
              status: NODE_ENV === "development" ? "pending" : "complete",
              type: "live",
              $sort: { createdAt: "desc" },
            },
          })

          if (data) {
            localStorage.setItem(
              "@autopilot:currentDeployment",
              JSON.stringify(data)
            )
          }
        } catch (error) {
          console.error("Error prefetching deployment data")
        }
      }
    )
  }

  if (isLoading) {
    return null
  }

  return (
    <HStack spacing={4}>
      <FormProvider {...methods}>
        <AppSelectInput name="id" apps={data} />
      </FormProvider>
      <Button
        as={Link}
        to={`/admin/apps/${appId}/preview`}
        bg="white"
        _hover={{ bg: "gray.50" }}
        _active={{ bg: "gray.50" }}
        leftIcon={<FiSliders />}
      >
        Config
      </Button>
      <Button
        as={Link}
        to={`/admin/apps/${appId}/preview/new`}
        leftIcon={<FiEye />}
        colorScheme="blue"
        onMouseEnter={handlPrefetchGitReference}
      >
        Preview
      </Button>
    </HStack>
  )
}

export default AppHeaderActions
