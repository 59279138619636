import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Select,
  Spinner,
  Table,
  TableRowProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { Entity, maxLimitDefault } from "@jackfruit/common"
import { useEntityList } from "hooks/useEntityList"
import { get } from "lodash"
import React, { ChangeEvent, useEffect, useState } from "react"
import {
  FiArrowDown,
  FiArrowUp,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi"
import { Resources, ApiResultList } from "services/api"
export interface ColumnDefinition {
  header: string
  accessor?: string
  render?: (item: any) => React.ReactNode
}

export interface RowDefinition {
  getProperties: (item: any) => TableRowProps
}

interface Props {
  resource: Resources
  columns: ColumnDefinition[]
  showPagination?: boolean
  rowDefinition?: RowDefinition
  query?: any
  sortByCol?: string
  orderBy?: "asc" | "desc"
  limit?: number
  options?: Object
  data?: any
}

const TableSort: React.FC<Props> = ({
  resource,
  columns,
  showPagination = true,
  rowDefinition = {
    getProperties: (item: any) => {
      return {}
    },
  },
  query,
  sortByCol = "id",
  orderBy = "desc",
  options = {},
}) => {
  const itemsPerPageDefault = 50
  const [sortBy, setSortBy] = useState<any>({
    [sortByCol]: orderBy === "asc" ? -1 : 1,
  } as any)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageDefault)
  const [rowData, setRowData] = useState<ApiResultList<Entity>>({
    data: [],
    limit: 0,
    skip: 0,
    total: 0,
  })
  const [pageData, setPageData] = useState<Entity[]>([])

  const params = {
    query: {
      $limit: maxLimitDefault,
      $sort: { id: 1 },
      ...query,
    },
  }

  const { data: printData, isLoading } = useEntityList(
    resource,
    params,
    options
  )

  useEffect(() => {
    if (!isLoading) {
      setRowData(printData)
      setPageData(printData.data.slice(0, itemsPerPage))
    }
  }, [printData, isLoading, itemsPerPage])

  const handlePaginationLocally = (page: number) => {
    const start = page * itemsPerPage
    const end = start + itemsPerPage
    const dataTmp = rowData.data.slice(start, end)

    setPageData(dataTmp)
  }

  const maxPages = Math.ceil(rowData.total / itemsPerPage) - 1

  const onSortColumnToggle = (column: string) => {
    const newSortBy: any = {}
    if (sortBy[column] && sortBy[column] === 1) {
      newSortBy[column] = -1
    } else {
      newSortBy[column] = 1
    }

    setSortBy(newSortBy)

    const sortedData = rowData.data.sort((a: any, b: any) => {
      if (newSortBy[column] === 1) {
        return a[column] > b[column] ? 1 : -1
      } else {
        return a[column] < b[column] ? 1 : -1
      }
    })

    setRowData({ ...rowData, data: sortedData })
    setCurrentPage(0)
    handlePaginationLocally(0)
  }

  const onItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(event.target.value))
  }

  const onPreviousClick = () => {
    if (currentPage > 0) {
      const newPage = currentPage - 1
      setCurrentPage(newPage)
      handlePaginationLocally(newPage)
    }
  }

  const onNextClick = () => {
    if (currentPage < maxPages) {
      const newPage = currentPage + 1
      setCurrentPage(newPage)
      handlePaginationLocally(newPage)
    }
  }

  const onBeginClick = () => {
    setCurrentPage(0)
    handlePaginationLocally(0)
  }

  const onEndClick = () => {
    setCurrentPage(maxPages)
    handlePaginationLocally(maxPages)
  }

  if (isLoading) {
    return <Spinner color="gray.400" />
  }

  return (
    <Box>
      <Table w="100%" overflowX="scroll">
        <Thead>
          <Tr borderBottomWidth={1} borderBottomColor="gray.100">
            {columns.map((c, i) => {
              const header =
                c.accessor !== undefined ? (
                  <Flex
                    alignItems="center"
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() => onSortColumnToggle(c.accessor!)}
                  >
                    {c.header}
                    {sortBy[c.accessor] === 1 && (
                      <Icon ml={2} boxSize={5} as={FiArrowUp} />
                    )}
                    {sortBy[c.accessor] === -1 && (
                      <Icon ml={2} boxSize={5} as={FiArrowDown} />
                    )}
                  </Flex>
                ) : (
                  c.header
                )

              return (
                <Th key={i} px={5} py={5} textAlign="left">
                  {header}
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody bg="white">
          {pageData.map((entity: any) => {
            return (
              <Tr
                key={entity.id}
                borderBottomWidth={1}
                borderBottomColor="gray.100"
                _hover={{
                  bg: "gray.50",
                }}
                {...rowDefinition.getProperties(entity)}
              >
                {columns.map((c, colIndex) => {
                  if (c.render) {
                    return (
                      <Td key={colIndex} px={5} py={1}>
                        {c.render(entity)}
                      </Td>
                    )
                  } else if (c.accessor) {
                    return (
                      <Td key={colIndex} px={5} py={1}>
                        <Text>{get(entity, c.accessor)}</Text>
                      </Td>
                    )
                  }
                  return null
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      {showPagination && (
        <Flex justifyContent="space-between" paddingX={3}>
          <HStack justifyContent="center" spacing={5} p={2}>
            <Button
              colorScheme="gray"
              size="sm"
              onClick={onBeginClick}
              isDisabled={currentPage === 0}
            >
              <FiChevronsLeft />
            </Button>
            <Button
              colorScheme="gray"
              size="sm"
              onClick={onPreviousClick}
              isDisabled={currentPage === 0}
            >
              <FiChevronLeft />
            </Button>
          </HStack>
          <HStack spacing={5}>
            <Text as="span">
              page {currentPage + 1} / {maxPages + 1} ({rowData.total} lines)
            </Text>
            <Box w="115px">
              <Select
                value={itemsPerPage}
                onChange={onItemsPerPageChange}
                size="sm"
              >
                {" "}
                <option value={50}>Show 50</option>
                <option value={100}>Show 100</option>
                <option value={250}>Show 250</option>
                <option value={500}>Show 500</option>
              </Select>
            </Box>
          </HStack>
          <HStack justifyContent="center" spacing={5} p={2}>
            <Button
              colorScheme="gray"
              size="sm"
              onClick={onNextClick}
              isDisabled={currentPage === maxPages}
            >
              <FiChevronRight />
            </Button>

            <Button
              colorScheme="gray"
              size="sm"
              onClick={onEndClick}
              isDisabled={currentPage === maxPages}
            >
              <FiChevronsRight />
            </Button>
          </HStack>
        </Flex>
      )}
    </Box>
  )
}

export default TableSort
