import {
  FormControl,
  FormLabel,
  InputGroup,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import React, { useCallback } from "react"
import { Controller, useFormContext } from "react-hook-form"

const nestedPrefix = "header"
interface NavType {
  typeName: string
  idName: string
}
interface Props {
  readOnly?: boolean
  navigationOptions: any
  prefix?: boolean
}

const BlockHeaderFields: React.FC<Props> = ({
  readOnly = false,
  navigationOptions,
  prefix = true,
}) => {
  const { register, control } = useFormContext()

  const setName = useCallback(
    (name: string) => {
      return prefix ? `${nestedPrefix}.${name}` : name
    },
    [prefix]
  )
  const navigationTypeList: NavType[] = [
    { typeName: "Top Nav", idName: "topNavigation" },
    { typeName: "Brand Nav", idName: "brandNavigation" },
    { typeName: "Main Nav", idName: "mainNavigation" },
    { typeName: "Mobile Nav", idName: "mobileNavigation" },
  ]

  return (
    <Tabs>
      <TabList>
        {navigationTypeList.map((navType, index) => {
          const { typeName } = navType
          return <Tab key={index}>{typeName}</Tab>
        })}
      </TabList>

      <TabPanels>
        {navigationTypeList.map((navType, index) => {
          const { idName } = navType
          return (
            <TabPanel key={index}>
              <FormControl>
                <FormLabel>Navigation</FormLabel>
                <InputGroup>
                  <Select
                    ref={register}
                    name={setName(`${idName}Id`)}
                    placeholder="Select option"
                    isDisabled={readOnly}
                  >
                    {navigationOptions}
                  </Select>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mt="1rem">Background Color</FormLabel>
                <Controller
                  as={ColorPickerInput as any}
                  name={setName(`${idName}BackgroundColor`)}
                  control={control}
                  placeholder="#424242"
                  disabled={readOnly}
                  defaultValue=""
                />
              </FormControl>
              <FormControl>
                <FormLabel mt="1rem">Text Color</FormLabel>
                <Controller
                  as={ColorPickerInput as any}
                  name={setName(`${idName}Color`)}
                  control={control}
                  placeholder="#424242"
                  disabled={readOnly}
                  defaultValue=""
                />
              </FormControl>
            </TabPanel>
          )
        })}
      </TabPanels>
    </Tabs>
  )
}

export default BlockHeaderFields
