import { PageEntity } from "@jackfruit/common"
import isoHtmlCountryCodes from "data/isoHtmlCountryCodes"
import { formatISO9075, parseISO } from "date-fns"

export const forceNullable = (obj: any, keys: string[]) => {
  let object = { ...obj }
  keys.forEach(key => {
    if (object[key] === "") {
      object[key] = null
    }
  })
  return object
}

export const removeKeys = (obj: any, keys: string[]): any =>
  obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.map(item => removeKeys(item, keys))
    : Object.keys(obj)
        .filter(k => !keys.includes(k))
        .reduce(
          (acc, x) => Object.assign(acc, { [x]: removeKeys(obj[x], keys) }),
          {}
        )

export const formatDateForApi = (date: Date): string => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  const sanitizedDate = `${year}-${month}-${day}`

  return formatISO9075(parseISO(sanitizedDate))
}

export const getExtraLines = (previous: string[], next: string[]): string[] => {
  return next.reduce<string[]>((acc, current, index) => {
    if (previous[index] !== current) {
      acc.push(current)
    }
    return acc
  }, [])
}

export const formatCountable = (count: number, countable: string): string => {
  return count === 1 ? countable : `${countable}s`
}

export const getLocaleFullName = (code: string) => {
  const country = isoHtmlCountryCodes.find(
    countryList => countryList.code === code
  )
  return country ? country?.name : code
}

export const getLanguageFullName = (lang: string) => {
  switch (lang) {
    case "fr-CA":
      return "French(CA)"
    case "ja-JP":
      return "Japanese"
    case "es-US":
      return "Spanish"
    case "en-US":
      return "English"
    default:
      return "English"
  }
}

export const generatePathForPage = (
  page: PageEntity,
  pages: PageEntity[]
): string => {
  let node = page
  const slugs = []
  slugs.push(node.slug)
  while (node.parentId !== null) {
    // eslint-disable-next-line no-loop-func
    node = pages.find(p => p.id === node.parentId)!
    slugs.push(node.slug)
  }

  // force trailing slashes on generated urls
  let fullPath = slugs.reverse().join("/")
  if (fullPath.length && fullPath.charAt(fullPath.length - 1) !== "/") {
    fullPath += "/"
  }

  if (!fullPath.startsWith("/")) {
    fullPath = `/${fullPath}`
  }

  return fullPath
}

// Validate a git version
// valid version: v5.2.0 & invalid version: v5.2.0-rc1

export const isValidGitVersion = (version: string): boolean => {
  const regex = /^v\d+\.\d+\.\d+$/
  return regex.test(version)
}

export const removeEmptyObjects = (array: any[]): any[] => {
  return array.filter(obj => !Object.values(obj).every(value => value === ""))
}
