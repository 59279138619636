import { Entity } from "@jackfruit/common"
import { useMutation, useQueryClient } from "react-query"
import api, { Resources } from "services/api"
import { useAppRevision } from "./useAppRevision"
import { useCurrentAppId } from "./useCurrentAppId"
import { useCustomToast } from "./useCustomToast"

interface PatchHookOptions {
  showNotification: boolean
  onSuccess: (data: any) => void
}

export const usePatchEntity = <T extends Entity>(
  resource: Resources,
  options: PatchHookOptions = { showNotification: true, onSuccess: () => {} }
) => {
  const appId = useCurrentAppId()
  const { bumpRevision } = useAppRevision()
  const toast = useCustomToast()
  const queryClient = useQueryClient()

  return useMutation(
    (data: Partial<T>) => api.patch(resource, data.id, data, { appId }),
    {
      onMutate: async (data: Partial<T>) => {
        await queryClient.cancelQueries(resource)
        const previousEntity = queryClient.getQueryData([resource, data.id])
        queryClient.setQueryData([resource, data.id], (old: any) => {
          return {
            ...old,
            ...data,
          }
        })
        return previousEntity
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(resource, previousValue)
      },
      onSuccess: async (data: T) => {
        queryClient.invalidateQueries(resource)
        if (options.showNotification) {
          toast({
            title: `${resource} updated.`,
            description: `${resource} has been succesfully updated`,
            status: "success",
            duration: 3000,
            isClosable: true,
          })
          bumpRevision()
        }
        options.onSuccess(data)
      },
    }
  )
}
