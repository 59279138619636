import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { TwitterPicker } from 'react-color'

interface Props {
  name: string
  value?: string
  onChange: (value: string) => void
  ref?: any
  disabled?: boolean
}

const ColorPickerInput: React.FC<Props> = ({
  name,
  value,
  ref,
  onChange,
  disabled = false,
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value || '')

  useEffect(() => {
    if (value) {
      setCurrentValue(value)
      onChange(value)
    }
  }, [value, onChange, setCurrentValue])

  return (
    <>
      <InputGroup position="relative" zIndex="0">
        <Input
          ref={ref}
          name={name}
          readOnly={true}
          value={currentValue}
          autoComplete="off"
          disabled={disabled}
        />
        <InputRightElement
          children={
            <Box style={{ backgroundColor: currentValue }} borderRadius="md">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Box>
          }
        />
      </InputGroup>
      <Box mt={4}>
        <TwitterPicker
          color={currentValue}
          onChangeComplete={(color) => {
            if (!disabled) {
              onChange(color.hex)
              setCurrentValue(color.hex)
            }
          }}
        />
      </Box>
    </>
  )
}

export default ColorPickerInput
