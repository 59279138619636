import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react"
import React, { useContext } from "react"
import { webappThemeConfig } from "@jackfruit/common"
import { CpContext, CpContextInterface } from "./CpContext"
import { replaceShortCodes } from "./replaceShortCodes"

const theme = extendTheme(webappThemeConfig)

interface Props {}

const CpResult: React.FC<Props> = () => {
  const { htmlValue } = useContext<CpContextInterface>(CpContext)

  const result = htmlValue ? replaceShortCodes(htmlValue) : ""

  return (
    <Box
      id="htmlPreview"
      width="full"
      height="full"
      // required to force loading fonts for preview
      // for some reason, everything loads correctly from the prodiver
      // but the font variable does not get overriden in the browser
      // here 'body' refers to theme.fonts.body
      fontFamily="body"
    >
      <ChakraProvider theme={theme} resetCSS={true} cssVarsRoot="#htmlPreview">
        <Box
          width="full"
          height="full"
          dangerouslySetInnerHTML={{ __html: result }}
        ></Box>
      </ChakraProvider>
    </Box>
  )
}

export default CpResult
