import { FormControl, FormLabel } from '@chakra-ui/react'
import { AppEntity } from '@jackfruit/common'
import FormContainer from 'components/FormContainer'
import FormSectionHeader from 'components/FormSectionHeader'
import { EntityFormProps } from 'forms/EntityFormProps'
import FormActions from 'forms/FormActions'
import ColorPickerInput from 'forms/inputs/ColorPickerInput'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingThemeForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }
  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Colours</FormSectionHeader>
      <FormControl>
        <FormLabel>Primary Colour</FormLabel>
        <Controller
          as={ColorPickerInput as any}
          name="primaryColor"
          control={control}
          placeholder="#424242"
        />
      </FormControl>
      <FormControl>
        <FormLabel>Secondary Colour</FormLabel>
        <Controller
          as={ColorPickerInput as any}
          name="secondaryColor"
          control={control}
          placeholder="#424242"
        />
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingThemeForm
