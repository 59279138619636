import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { CommonEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

interface Props {
  entity: CommonEntity
  isLoading: boolean
  onSubmit: (data: any) => void
}

const AppCommonFooterGeneralForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<any>({ defaultValues })
  const { handleSubmit, register, errors, control } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormSectionHeader>General</FormSectionHeader>
        <FormControl isInvalid={errors.hideFooter}>
          <InputGroup>
            <Checkbox
              ref={register}
              name="hideFooter"
              defaultChecked={entity?.hideFooter}
            >
              Hide Footer
            </Checkbox>
          </InputGroup>
          <FormErrorMessage>
            {errors.hideFooter && errors.hideFooter.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.showPoweredByAutopilotBanner}>
          <InputGroup>
            <Checkbox
              ref={register}
              name="showPoweredByAutopilotBanner"
              defaultChecked={entity?.showPoweredByAutopilotBanner}
            >
              Show "Powered by MEA" Banner
            </Checkbox>
          </InputGroup>
          <FormErrorMessage>
            {errors.showPoweredByAutopilotBanner &&
              errors.showPoweredByAutopilotBanner.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.poweredByAutopilotBannerText}>
          <FormLabel>Banner Text</FormLabel>
          <InputGroup>
            <Input
              ref={register}
              name="poweredByAutopilotBannerText"
              placeholder="Powered by MEA"
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.poweredByAutopilotBannerText &&
              errors.poweredByAutopilotBannerText.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.poweredByAutopilotRedirectUrl}>
          <FormLabel>Powered by MEA Redirect URL</FormLabel>
          <InputGroup>
            <Input
              ref={register}
              name="poweredByAutopilotRedirectUrl"
              placeholder="Enter redirect URL e.g. https://www.autopilotprint.com/"
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.poweredByAutopilotRedirectUrl &&
              errors.poweredByAutopilotRedirectUrl.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>Text Colour</FormLabel>
          <Controller
            as={ColorPickerInput as any}
            name="poweredByAutopilotTextColour"
            register={register}
            control={control}
            placeholder="#333333"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Background Colour</FormLabel>
          <Controller
            as={ColorPickerInput as any}
            name="poweredByAutopilotBg"
            register={register}
            control={control}
            placeholder="#424242"
          />
        </FormControl>
        <FormControl isInvalid={errors.showSocialMediaLinks}>
          <InputGroup>
            <Checkbox
              ref={register}
              name="showSocialMediaLinks"
              defaultChecked={entity?.showSocialMediaLinks}
            >
              Show social media links
            </Checkbox>
          </InputGroup>
          <FormErrorMessage>
            {errors.showSocialMediaLinks && errors.showSocialMediaLinks.message}
          </FormErrorMessage>
        </FormControl>

        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppCommonFooterGeneralForm
