import React from 'react'
import { Box } from '@chakra-ui/react'
import BlockFulfillmentForm from '../forms/block-fulfillment/BlockFulfillmentForm'
import { useEntity } from 'hooks/useEntity'
import { usePatchEntity } from 'hooks/usePatchEntity'
import { BlockEntity, BlockFulfillmentEntity } from '@jackfruit/common'

interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockFulfillment: React.FC<Props> = ({ block, readOnly = false }) => {
  const { data: entity, isLoading } = useEntity<BlockFulfillmentEntity>(
    'blocks-fulfillment',
    block.childId
  )

  const {
    mutate: saveBlock,
    status: patchStatus,
  } = usePatchEntity<BlockFulfillmentEntity>('blocks-fulfillment')

  const isPatching = patchStatus === 'loading'

  const onSubmit = (data: any) => {
    saveBlock({ id: entity.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockFulfillmentForm
      entity={entity}
      isLoading={isPatching}
      onSubmit={onSubmit}
      readOnly={readOnly}
    />
  )
}

export default BlockFulfillment
