import React, { useCallback } from "react"
import {
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react"
import { Controller, useFormContext } from "react-hook-form"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import BlockFooterRows from "./BlockFooterRows"
import BlockFooterColumns from "./BlockFooterColumns"
import { BlockFooterEntity } from "@jackfruit/common"

const nestedPrefix = "footer"
const rowsDef: number[] = [2, 4, 1] // Number of columns per row

interface Props {
  readOnly?: boolean
  prefix?: boolean
  entity: BlockFooterEntity
}

const BlockFooterFields: React.FC<Props> = ({
  readOnly = false,
  prefix = true,
  entity,
}) => {
  const { register, control } = useFormContext()

  const setName = useCallback(
    (name: string) => {
      return prefix ? `${nestedPrefix}.${name}` : name
    },
    [prefix]
  )

  if (!entity) {
    return null
  }

  return (
    <Tabs>
      <TabList>
        <BlockFooterRows rowsDef={rowsDef} />
      </TabList>

      <TabPanels>
        {rowsDef.map((colsLength, index) => {
          const rowIndex = index + 1
          return (
            <TabPanel key={index}>
              <FormControl marginBottom={15}>
                <FormLabel>Row {rowIndex} - background color</FormLabel>
                <Controller
                  as={ColorPickerInput as any}
                  name={setName(`row${rowIndex}Bg`)}
                  register={register}
                  control={control}
                  placeholder="#424242"
                  disabled={readOnly}
                  defaultValue={
                    entity[`row${rowIndex}Bg` as keyof BlockFooterEntity]
                  }
                />
              </FormControl>
              <BlockFooterColumns
                setName={setName}
                colsLength={colsLength}
                rowIndex={rowIndex}
                readOnly={readOnly}
              />
            </TabPanel>
          )
        })}
      </TabPanels>
    </Tabs>
  )
}

export default BlockFooterFields
