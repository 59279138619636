import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormContainer from 'components/FormContainer'
import FormActions from 'forms/FormActions'
import BlockFulfillmentFields from './BlockFulfillmentFields'
import { BlockFulfillmentEntity } from '@jackfruit/common'

interface Props {
  entity: BlockFulfillmentEntity
  isLoading: boolean
  readOnly: boolean
  onSubmit: (data: BlockFulfillmentEntity) => void
}

const BlockFulfillmentForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
  readOnly = false,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockFulfillmentEntity>({ defaultValues })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <BlockFulfillmentFields
          readOnly={readOnly}
          entity={entity}
          prefix={false}
        />

        <FormActions
          canCancel={false}
          isLoading={isLoading}
          entity={entity}
          readOnly={readOnly}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockFulfillmentForm
