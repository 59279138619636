import { Badge, Text } from "@chakra-ui/react"
import {
  maxLimitDefault,
  PrintServiceProductConfigurationEntity,
} from "@jackfruit/common"
import Body from "components/Body"
import { ColumnDefinition, RowDefinition } from "components/Table"
import TableBoolean from "components/TableBoolean"
import TableEditableField from "components/TableEditableField"
import TableResetField from "components/TableResetField"
import TableSort from "components/TableSort"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { EntityType } from "./PrintServiceConfigurationTable"
import PrintServiceProductConfigurationTableAction from "./PrintServiceProductConfigurationTableAction"

interface Props {
  canGoBack?: boolean
  title: string
  entityType: EntityType
  entityId: number
  printServiceId: number
}

interface EditColumnInterface {
  printServiceProduct: PrintServiceProductConfigurationEntity
}

interface ToggleColumnInterface extends EditColumnInterface {
  field: keyof PrintServiceProductConfigurationEntity
}

const ToggleEnabledColumn: React.FC<ToggleColumnInterface> = ({
  printServiceProduct,
  field,
}) => {
  const { mutate: patchPrintService } =
    usePatchEntity<PrintServiceProductConfigurationEntity>(
      "print-service-product-configurations",
      {
        showNotification: false,
        onSuccess: () => {},
      }
    )

  const toggleField = () => {
    patchPrintService({
      id: printServiceProduct.id,
      [field]: !printServiceProduct[field],
    })
  }

  return (
    <TableBoolean value={!!printServiceProduct[field]} onClick={toggleField} />
  )
}

const columns: ColumnDefinition[] = [
  {
    header: "Enabled",
    accessor: "isEnabled",
    render: (printServiceProduct: PrintServiceProductConfigurationEntity) => (
      <ToggleEnabledColumn
        printServiceProduct={printServiceProduct}
        field="isEnabled"
      />
    ),
  },
  {
    header: "Default Square",
    accessor: "isDefaultSquareProduct",
    render: (printServiceProduct: PrintServiceProductConfigurationEntity) => (
      <ToggleEnabledColumn
        printServiceProduct={printServiceProduct}
        field="isDefaultSquareProduct"
      />
    ),
  },
  {
    header: "Default Rectangle",
    accessor: "isDefaultRectangleProduct",
    render: (printServiceProduct: PrintServiceProductConfigurationEntity) => (
      <ToggleEnabledColumn
        printServiceProduct={printServiceProduct}
        field="isDefaultRectangleProduct"
      />
    ),
  },
  {
    header: "Remote Id",
    accessor: "remoteId",
  },

  {
    header: "Short Description",
    accessor: "shortDescription",
    render: (printServiceProduct: PrintServiceProductConfigurationEntity) => (
      <Text>
        {printServiceProduct.shortDescription}{" "}
        <Badge colorScheme="gray">{printServiceProduct.categoryName}</Badge>
      </Text>
    ),
  },
  {
    header: "Product Code",
    accessor: "productCode",
  },
  {
    header: "Sort Order",
    accessor: "position",
    render: (printServiceProduct: PrintServiceProductConfigurationEntity) => (
      <TableEditableField
        resource="print-service-product-configurations"
        entity={printServiceProduct}
        field="position"
        width={130}
      />
    ),
  },
  {
    header: "",
    render: (printServiceProduct: PrintServiceProductConfigurationEntity) => {
      if (printServiceProduct.hasBeenOverriden)
        return (
          <TableResetField
            resource="print-service-product-configurations"
            entity={printServiceProduct}
            fields={["isEnabled", "position"]}
          />
        )
    },
  },
]

const rowDefinition: RowDefinition = {
  getProperties: (
    printServiceProduct: PrintServiceProductConfigurationEntity
  ) => {
    return printServiceProduct.isUnavailable
      ? {
          bg: "red.100",
          _hover: {
            bg: "red.200",
          },
        }
      : {}
  },
}

const PrintServiceProductConfigurationTable: React.FC<Props> = ({
  canGoBack = true,
  title,
  entityType,
  entityId,
  printServiceId,
}) => {
  const query = {
    entityType,
    entityId,
    printServiceId,
  }

  return (
    <Body
      canGoBack={canGoBack}
      padding={0}
      title={title}
      headerActions={
        <PrintServiceProductConfigurationTableAction
          entityType={entityType}
          entityId={entityId}
          printServiceId={printServiceId}
        />
      }
    >
      <TableSort
        resource="print-service-product-configurations"
        columns={columns}
        rowDefinition={rowDefinition}
        query={query}
        limit={maxLimitDefault}
      />
    </Body>
  )
}

export default PrintServiceProductConfigurationTable
